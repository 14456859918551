import React from "react"

function Grid({ ...props }) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 227 227"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.188 24.469A24.281 24.281 0 0 1 24.468.187h48.563A24.281 24.281 0 0 1 97.312 24.47V73.03a24.281 24.281 0 0 1-24.28 24.281H24.468A24.281 24.281 0 0 1 .187 73.032V24.468Zm24.28-8.094a8.094 8.094 0 0 0-8.093 8.094V73.03a8.094 8.094 0 0 0 8.094 8.094H73.03a8.094 8.094 0 0 0 8.094-8.094V24.47a8.094 8.094 0 0 0-8.094-8.094H24.47Zm105.22 8.094A24.283 24.283 0 0 1 153.969.187h48.562a24.283 24.283 0 0 1 24.281 24.282V73.03a24.283 24.283 0 0 1-24.281 24.281h-48.562a24.283 24.283 0 0 1-24.281-24.28V24.468Zm24.281-8.094a8.091 8.091 0 0 0-8.094 8.094V73.03a8.094 8.094 0 0 0 8.094 8.094h48.562a8.091 8.091 0 0 0 8.094-8.094V24.47a8.094 8.094 0 0 0-8.094-8.094h-48.562ZM.188 153.969a24.283 24.283 0 0 1 24.28-24.281h48.563a24.283 24.283 0 0 1 24.281 24.281v48.562a24.283 24.283 0 0 1-24.28 24.281H24.468A24.283 24.283 0 0 1 .187 202.531v-48.562Zm24.28-8.094a8.094 8.094 0 0 0-8.093 8.094v48.562a8.091 8.091 0 0 0 8.094 8.094H73.03a8.094 8.094 0 0 0 8.094-8.094v-48.562a8.091 8.091 0 0 0-8.094-8.094H24.47Zm105.22 8.094c0-6.44 2.558-12.616 7.111-17.17a24.285 24.285 0 0 1 17.17-7.111h48.562c6.44 0 12.616 2.558 17.17 7.111a24.285 24.285 0 0 1 7.111 17.17v48.562c0 6.44-2.558 12.616-7.111 17.17a24.285 24.285 0 0 1-17.17 7.111h-48.562a24.285 24.285 0 0 1-17.17-7.111 24.285 24.285 0 0 1-7.111-17.17v-48.562Zm24.281-8.094a8.091 8.091 0 0 0-8.094 8.094v48.562a8.091 8.091 0 0 0 8.094 8.094h48.562a8.091 8.091 0 0 0 8.094-8.094v-48.562a8.091 8.091 0 0 0-8.094-8.094h-48.562Z"
        fill="#527693"
      />
    </svg>
  )
}

export default Grid
