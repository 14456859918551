import React from "react"

function List({ ...props }) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 259 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M157.11 28.697a8 8 0 0 0 0 16v-16Zm92.89 16a8 8 0 0 0 0-16v16Zm-92.89 0H250v-16h-92.89v16ZM157.11 67.688a8 8 0 0 0 0 16v-16Zm92.89 16a8 8 0 0 0 0-16v16Zm-92.89 0H250v-16h-92.89v16ZM157.11 175.486a8 8 0 0 0 0 16v-16Zm92.89 16a8 8 0 0 0 0-16v16Zm-92.89 0H250v-16h-92.89v16ZM157.11 214.478a8 8 0 1 0 0 16v-16Zm92.89 16a8 8 0 0 0 0-16v16Zm-92.89 0H250v-16h-92.89v16ZM0 27.846A27.846 27.846 0 0 1 27.846 0h55.69a27.845 27.845 0 0 1 27.846 27.846v55.69a27.845 27.845 0 0 1-27.846 27.846h-55.69A27.846 27.846 0 0 1 0 83.536v-55.69Zm27.846-9.282a9.282 9.282 0 0 0-9.282 9.282v55.69a9.282 9.282 0 0 0 9.282 9.282h55.69a9.282 9.282 0 0 0 9.282-9.282v-55.69a9.282 9.282 0 0 0-9.282-9.282h-55.69ZM0 176.355a27.846 27.846 0 0 1 27.846-27.846h55.69a27.845 27.845 0 0 1 27.846 27.846v55.691a27.844 27.844 0 0 1-27.846 27.845h-55.69A27.846 27.846 0 0 1 0 232.046v-55.691Zm27.846-9.282a9.284 9.284 0 0 0-9.282 9.282v55.691a9.283 9.283 0 0 0 9.282 9.281h55.69a9.284 9.284 0 0 0 9.282-9.281v-55.691a9.284 9.284 0 0 0-9.282-9.282h-55.69Z"
        fill="#527693"
      />
    </svg>
  )
}

export default List
